import React, { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, Grid } from "@mui/material";
import Logo from "../assets/logo";
import {
    Email,
    Facebook,
    Instagram,
    Phone,
    Twitter,
    WhatsApp,
} from "@mui/icons-material";
import Whatsapp from "../assets/whatsapp";
import { Link } from "react-router-dom";

function Footer() {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <Fragment>
            <Grid
                container
                marginLeft={isMobile ? 4 : 8}
                marginTop={6}
                gap={8}
                maxWidth={"80vw"}
            >
                <Grid xs={12} md={2}>
                    <Logo />
                    <Typography
                        marginY={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="20px"
                        color="#202020"
                    >
                        Sindicato dos Trabalhadores na Administração Pública e
                        Autarquias no Município de São Paulo
                    </Typography>
                </Grid>
                <Grid xs={12} md={2}>
                    <Typography
                        marginY={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#EB2227"
                    >
                        Sede
                    </Typography>
                    <Typography
                        marginY={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="20px"
                        color="#202020"
                    >
                        Rua da Quitanda, 101 - Centro São Paulo - SP
                    </Typography>

                    <Button
                        variant="text"
                        startIcon={<Phone style={{ width: 16 }} />}
                        role="button"
                        tabIndex={1}
                        style={{
                            marginY: 1,
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#202020",
                            marginRight: 50,
                        }}
                    >
                        11 2129 2999
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<WhatsApp style={{ width: 16 }} />}
                        role="button"
                        tabIndex={2}
                        style={{
                            marginY: 1,
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#202020",
                        }}
                    >
                        11 97025-5497
                    </Button>
                    <Button
                        variant="text"
                        startIcon={<Email style={{ width: 16 }} />}
                        role="button"
                        tabIndex={3}
                        style={{
                            marginY: 1,
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#202020",
                        }}
                    >
                        <a
                            aria-label="envio de email para contato@sindsep-sp.org.br"
                            href="mailto:contato@sindsep-sp.org.br"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                                textTransform: "lowercase",
                            }}
                        >
                            contato@sindsep-sp.org.br
                        </a>
                    </Button>
                </Grid>
                <Grid xs={12} md={1}>
                    <Typography
                        marginY={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#EB2227"
                    >
                        Nossas Redes
                    </Typography>
                    <Box>
                        <a
                            aria-label="redirecionamento para pagina do facebook"
                            href="https://www.facebook.com/sindsep"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <Facebook style={{ color: "#202020" }} />
                        </a>
                        <a
                            aria-label="redirecionamento para pagina do instagram"
                            href="https://www.instagram.com/sindsepsp"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <Instagram style={{ color: "#202020" }} />
                        </a>
                        <a
                            aria-label="redirecionamento para pagina do twitter"
                            href="https://twitter.com/sindsep"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <Twitter style={{ color: "#202020" }} />
                        </a>
                    </Box>
                    <Link
                        to="/assessoria"
                        aria-label="link para assessoria"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Typography
                            marginY={3}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="20px"
                            color="#EB2227"
                        >
                            Assessoria de Imprensa
                        </Typography>
                    </Link>
                </Grid>

                <Grid xs={12} md={3}>
                    <a
                        aria-label="redirecionamento para pagina do whatsapp"
                        href="https://api.whatsapp.com/send?phone=5511970255497"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            color: "#202020",
                            textDecoration: "none",
                            textTransform: "lowercase",
                        }}
                    >
                        <Typography
                            marginY={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="20px"
                            color="#EB2227"
                        >
                            Participe do nosso grupo
                        </Typography>
                        <Typography
                            marginY={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="20px"
                            color="#202020"
                        >
                            Receba as novidades do Sindsep no WhatsApp
                        </Typography>

                        <Button
                            size="small"
                            variant="outlined"
                            color="success"
                            startIcon={<Whatsapp />}
                            style={{
                                borderRadius: 100,
                                color: "#50CD5D",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                                marginTop: "20px",
                            }}
                        >
                            ASSINAR WHATSAPP
                        </Button>
                    </a>
                </Grid>
            </Grid>
            <Box marginLeft={isMobile ? 4 : 8} marginTop={2} marginBottom={4}>
                <img
                    src="../files/fetam.png"
                    style={{ marginRight: 24 }}
                    alt="logo da fetam"
                />
                <img
                    src="../files/cut.png"
                    style={{ marginRight: 24 }}
                    alt="logo da cut"
                />
                <img
                    src="../files/confetam.png"
                    style={{ marginRight: 24 }}
                    alt="logo da confetam"
                />
                <img
                    src="../files/psi.png"
                    style={{ marginRight: 24 }}
                    alt="logo da psi"
                />
                <img
                    src="../files/contram.png"
                    style={{ marginRight: 24 }}
                    alt="logo da contram"
                />
                <img
                    src="../files/tvt.png"
                    style={{ marginRight: 24 }}
                    alt="logo da tvt"
                />
                <img
                    src="../files/rba.png"
                    style={{ marginRight: 24 }}
                    alt="logo da rba"
                />
            </Box>
        </Fragment>
    );
}

export default Footer;
