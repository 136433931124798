import { Box, Button, CardMedia, Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import NewsItemLateral from "./commons/NewsItemLateral";

import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import NewsItem from "./commons/NewsItem";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

function AllNews({ isMobile, legal }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [news, setNews] = useState([]);

    useEffect(() => {
        setSearchParams({ page: Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1 });
        loadPage(Number(searchParams.get("page")))
    }, [searchParams]);

    async function loadPage(number) {
        setSearchParams({ page: number });
        if (number <= 0) {
            setSearchParams({ page: 1 });
            number = 1
        }

        const loadNews = async (number) => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/news?title=${searchParams.get("search") ? searchParams.get("search") : ""
                }${legal ? "&categorie=JURÍDICO" : ""}${searchParams.get("categorie") && searchParams.get("categorie") !== "TODAS AS CATEGORIAS" ? `&categorie=${searchParams.get("categorie")}` : ""}&limit=30&page=${Number(number) - 1}`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setNews(resp.data);
        };

        loadNews(number);

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
    }

    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${months[newDate.getMonth()]
            } de ${newDate.getFullYear()}`;
    }

    return (
        <Fragment>
            {legal && isMobile && (
                <a
                    href="https://infosind.com.br/Juridico/Login/%7B74248E80-6235-442F-884F-FC1323853621%7D"
                    target={"_blank"}
                    rel="noreferrer"
                    aria-label="Redirecionamento para o Infosind - juridico"
                    style={{
                        paddingLeft: isMobile ? 16 : 64,
                        paddingTop: isMobile ? 0 : 12,
                        textDecoration: "none",
                    }}
                >
                    <img
                        src={"../files/bannerprocessos.png"}
                        width={isMobile ? "90%" : "720px"}
                        alt="banner de processos"
                    />
                </a>
            )}


            <Grid
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={isMobile ? 2 : 8}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        {legal ? "Jurídico" : searchParams.get("categorie") ? `${searchParams.get("categorie")}` : "Todas as notícias"}
                    </Typography>
                </Grid>
                {news && news.length !== 0 && (
                    <Box style={{ width: '100vw', display: 'flex', gap: 16 }}>
                        <Grid
                            xs={12}
                            md={5.5}
                            display={"flex"}
                            style={{ maxWidth: "600px" }}
                            flexDirection={"column"}
                        >
                            <Link
                                aria-label="noticias"
                                to={`/noticia?link=${news[0].link}`}
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={
                                        news[0]?.capa?.includes("aws")
                                            ? news[0].capa
                                            : `https://admin.sindsep-sp.org.br/sistema/noticias/${news[0].ID}/capa/${news[0].capa}`
                                    }
                                    style={{
                                        maxWidth: "600px",
                                        maxHeight: "400px",
                                        objectFit: "fill",
                                    }}
                                    alt="collection image"
                                />
                                <Grid container display={"flex"} gap={2} mt={1}>
                                    <Grid xs={6} md={"auto"}>
                                        <Typography
                                            color="#010101"
                                            fontFamily="Raleway"
                                            fontStyle="normal"
                                            fontWeight="400"
                                            fontSize="12px"
                                            lineHeight="14px"
                                        >
                                            {formatDate(news[0].incData)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        xs={"auto"}
                                        md={"auto"}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "2px 12px",
                                            backgroundColor: news[0].categorieColor,
                                            borderRadius: 50,
                                        }}
                                    >
                                        <Typography
                                            color="#010101"
                                            fontFamily="Raleway"
                                            fontStyle="normal"
                                            fontWeight="700"
                                            fontSize="10px"
                                            lineHeight="14px"
                                            letterSpacing={"0.1em"}
                                        >
                                            {news[0].categorie}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography
                                    color="#010101"
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="24px"
                                    lineHeight="28px"
                                    marginTop={2}
                                >
                                    {news[0].titulo}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid
                            xs={12}
                            md={2.8}
                            display={"flex"}
                            flexDirection={"column"}
                            borderLeft={1}
                            borderRight={1}
                            padding={"0px 8px"}
                            alignItems={"center"}
                        >
                            <NewsItem
                                image={
                                    news[1]?.capa?.includes("aws")
                                        ? news[1].capa
                                        : `https://admin.sindsep-sp.org.br/sistema/noticias/${news[1].ID}/capa/${news[1].capa}`
                                }
                                date={formatDate(news[1].incData)}
                                description={news[1].titulo}
                                link={news[1].link}
                                categorie={news[1].categorie}
                                categorieColor={news[1].categorieColor}
                            />

                            <NewsItem
                                image={
                                    news[2]?.capa?.includes("aws")
                                        ? news[2].capa
                                        : `https://admin.sindsep-sp.org.br/sistema/noticias/${news[2].ID}/capa/${news[2].capa}`
                                }
                                date={formatDate(news[2].incData)}
                                description={news[2].titulo}
                                link={news[2].link}
                                categorie={news[2].categorie}
                                categorieColor={news[2].categorieColor}
                            />
                        </Grid>
                        <Grid
                            xs={12}
                            md={2.8}
                            display={"flex"}
                            flexDirection={"column"}
                            gap={2}
                        >
                            {[news[3], news[4], news[5], news[6], news[7]].map(
                                (item, index) => (
                                    <Box
                                        borderBottom={1}
                                        paddingBottom={2}
                                        key={index}
                                    >
                                        <Link
                                            aria-label="noticias"
                                            to={`/noticia?link=${item.link}`}
                                            rel="noreferrer"
                                            style={{
                                                textDecoration: "none",
                                            }}
                                        >
                                            <Grid container display={"flex"}>
                                                <Grid xs={"auto"} md={"auto"}>
                                                    <Typography
                                                        color="#010101"
                                                        fontFamily="Raleway"
                                                        fontStyle="normal"
                                                        fontWeight="400"
                                                        fontSize="12px"
                                                        lineHeight="14px"
                                                    >
                                                        {formatDate(item.incData)}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    xs={"auto"}
                                                    md={"auto"}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        padding: "2px 12px",
                                                        backgroundColor:
                                                            item.categorieColor,
                                                        borderRadius: 50,
                                                        marginLeft: 8,
                                                    }}
                                                >
                                                    <Typography
                                                        color="#010101"
                                                        fontFamily="Raleway"
                                                        fontStyle="normal"
                                                        fontWeight="700"
                                                        fontSize="10px"
                                                        lineHeight="14px"
                                                        letterSpacing={"0.1em"}
                                                    >
                                                        {item.categorie}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography
                                                color="#010101"
                                                fontFamily="Raleway"
                                                fontStyle="normal"
                                                fontWeight="600"
                                                fontSize="14px"
                                                lineHeight="16px"
                                                marginTop={1}
                                            >
                                                {item.titulo}
                                            </Typography>
                                        </Link>
                                    </Box>
                                )
                            )}
                        </Grid></Box>)}
                <Grid xs={12} md={12} display={"flex"} padding={"0px 10px"}>
                    <Grid
                        container
                        marginY={6}
                        gap={2}
                        display={"flex"}
                        position={"relative"}
                    >
                        {news.slice(8).map((item) => (
                            <Grid
                                xs={12}
                                md={4.5}
                                display={"flex"}
                                padding={"0px 10px"}
                            >
                                <NewsItemLateral
                                    image={
                                        item.capa && item?.capa?.includes("aws")
                                            ? item.capa
                                            : `https://admin.sindsep-sp.org.br/sistema/noticias/${item.ID}/capa/${item.capa}`
                                    }
                                    date={item.incData}
                                    title={item.titulo}
                                    description={item.description}
                                    link={item.link}
                                    categorie={item.categorie}
                                    categorieColor={item.categorieColor}
                                    isMobile={isMobile}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        xs={12}
                        md={2.8}
                        display={"flex"}
                        padding={"0px 10px"}
                        position={"fixed"}
                        right={2}
                        marginTop={4}
                    >
                        {legal && !isMobile && (
                            <a
                                href="https://infosind.com.br/Juridico/Login/%7B74248E80-6235-442F-884F-FC1323853621%7D"
                                target={"_blank"}
                                rel="noreferrer"
                                aria-label="Redirecionamento para o Infosind - juridico"
                                style={{
                                    paddingLeft: 8,
                                    textDecoration: "none",
                                }}
                            >
                                <img
                                    src={"../files/bannerprocessos.png"}
                                    width={"90%"}
                                    alt="banner de processos"
                                />
                            </a>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                xs={12}
                md={12}
                padding={"16px 0px"}
                color="#FFFFFF"
                borderBottom={1}
                display={"flex"}
                justifyContent={"center"}
            >
                <Pagination
                    color="warning"
                    variant="outlined" count={130} page={Number(searchParams.get("page"))} onChange={(e, number) => loadPage(number)}
                    renderItem={(item) => (
                        <PaginationItem
                            style={{
                                borderRadius: 100,
                                color: "#EB2227",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}

                            slots={{ previous: ArrowBackOutlined, next: ArrowForwardOutlined }}
                            {...item}
                        />)}
                />
            </Grid>
        </Fragment>
    );
}

export default AllNews;
